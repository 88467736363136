
import React from "react"
import { useEffect } from "react"
const componentHtml = (
    <div 
    id="stamped-rewards-widget" 
    data-widget-type="rewards-earnings-v2" 
    >        
    </div>
)

const EarnPoints = (props) => {
  useEffect(() => {
    if (window.StampedFn) {
      window.StampedFn.initRewards({})
    }
  }, [])
  return (
    <div>
      <div style={{ marginTop: "0px" }}>{componentHtml}</div>
    </div>
  )
}

export default EarnPoints


