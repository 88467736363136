import React from "react"
import { useEffect } from "react"
const heroHTML = (
  <div id="stamped-rewards-widget" 
    data-widget-type="rewards-summary" 
    data-label-greeting-anonymous="Hello there" 
    data-label-greeting-description="Gain access to exclusive discounts every time you shop."
    data-label-signup="Redeem Now" 
    data-label-signin="Reward History">
  </div> 
)

const Hero = (props) => {
  useEffect(() => {
    if (window.StampedFn) {
      window.StampedFn.initRewards({})
    }
  }, [])
  return (
    <div>
      <div className="rewards-hero-wrapper">{heroHTML}</div>
    </div>
  )
}

export default Hero
