import React from "react"
import { useEffect } from "react"
const componentHtml = (
    <div id="stamped-rewards-widget" 
    data-widget-type="rewards-referral" 
    data-label-subtitle="(Hair)do unto others..." 
    data-label-title="Give $10, Get $10" 
    data-label-description="Give your friends $10 off their first order of $50+, and get $10 in points for each successful referral." 
    data-label-send="Send" 
    data-label-email-customer="Enter Your Email" 
    data-label-email-friend="Friend’s email address" 
    data-label-thank-you="Thanks for spreading the love!">        
    </div>

)

const Referral = (props) => {
  useEffect(() => {
    if (window.StampedFn) {
      window.StampedFn.initRewards({})
    }
  }, [])
  return (
    <div>
      <div style={{ marginTop: "0px" }}>{componentHtml}</div>
    </div>
  )
}

export default Referral


