import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import PageContents from "../components/Rewards/PageContents"
import { createGlobalStyle } from "styled-components"
import { ButtonStyleDark } from "../components/Core/Buttons/Styles"
import breakpoints from "../styles/breakpoints"
import colors from "../styles/colors"

const GlobalStyle = createGlobalStyle`
    .stamped-rewards-page-more {
        ${ButtonStyleDark};
        color: white !important;
        margin-top: 20px !important;
    }

    .stamped-launcher-button {
      display: block !important;
    }

    .stamped-rewards-widget-campaigns-earnings {
      .stamped-rewards-widget-item[data-campaign-id="639cd873-1c37-4095-916b-394622331590"] {
        .stamped-rewards-widget-campaign-value {
          font-size: 0;
        }
        .stamped-rewards-widget-campaign-value::before {
          content: "1 point";
          font-size: 16px;
        }
      }
    }

    .rewards-hero-wrapper {
      .stamped-rewards-widget-title {
        line-height: 1 !important;
        letter-spacing: 0 !important;
      }
      
      .stamped-rewards-widget-text {
        font-family: sofia-pro, sans-serif !important;
        font-weight: 400 !important;
        font-style: normal !important;
        font-size: 16px !important;
        line-height: 1.33 !important;
        max-width: 420px !important;
        margin: auto !important;
        min-width: 100% !important;
      }
    }

    @media (max-width: ${breakpoints.md}) {
      .rewards-hero-wrapper {
        background-size: 100vw 100vw;
        background-position: bottom center;
        #stamped-rewards-widget {
          padding-top: 0 !important;
          padding-bottom: 100vw !important;
          padding-left: 20px !important;
          padding-right: 20px !important;
        }
        .stamped-rewards-widget-text-holder {
          margin-top: 40px;
          .stamped-rewards-widget-title,
          .stamped-rewards-widget-text {
            min-width: 100%;
          }
        }
        .stamped-rewards-widget-btn {
          margin-right: 0;
        }
        .stamped-rewards-widget-text-holder {
          align-items: center !important;
          width: calc(100vw - 40px);
        }
        
        .stamped-rewards-widget-title {
          font-family: adobe-garamond-pro, serif;
          font-weight: 400;
          font-style: normal;
          font-size: 45px;
          line-height: 1;
          letter-spacing: -0.3px;	
        }
        
        .stamped-rewards-widget-text {
          min-width: calc(100% - 120px) !important;
          padding-left: 30px;
          padding-right: 30px;
        }   
      }
    }
    [data-widget-type="rewards-earnings-v2"] ul.stamped-rewards-earnings {
      display: flex !important;
      flex-wrap: wrap;
      margin: 10px auto;

      li {
        width: 262px;
        height: 262px;
        margin: 10px auto;
      }

      li:nth-child(6) {
        order: -1;
      }
    }

    @media (min-width: 600px) and (max-width: 959px) {
      [data-widget-type="rewards-earnings-v2"] ul.stamped-rewards-earnings {
        display: flex !important;
        flex-wrap: wrap;
        margin: 10px auto;
        max-width: 1122px;

        li {
          width: 262px;
          height: 262px;
          margin-right: 0;
          margin-left: 0;
        }

        li:nth-child(6) {
          order: -1;
          margin-right: calc((100% - 524px) / 2) !important;
          margin-left: calc((100% - 524px) / 4) !important;
        }

        li:nth-child(2n - 2) {
          margin-right: calc((100% - 524px) / 2);
          margin-left: calc((100% - 524px) / 4);
        }

        li:nth-child(2n - 1) {
          margin-right: calc((100% - 524px) / 4);
        }
      }
    }

    @media (min-width: 960px) {
      [data-widget-type="rewards-earnings-v2"] ul.stamped-rewards-earnings {
        display: flex !important;
        flex-wrap: wrap;
        margin: 10px auto;
        max-width: 1122px;

        li {
          width: 262px;
          height: 262px;
          margin-right: 0;
          margin-left: 0;
        }

        li:nth-child(6) {
          order: -1;
          margin-right: calc((100% - 786px) / 3) !important;
          margin-left: calc((100% - 786px) / 6) !important;
        }

        li:nth-child(3n - 2), li:nth-child(3n - 3) {
          margin-right: calc((100% - 786px) / 3);
        }

        li:nth-child(3n - 3) {
          margin-left: calc((100% - 786px) / 6);
        }

        li:nth-child(3n - 1) {
          margin-right: calc((100% - 786px) / 6);
        }
      }
    }

    [data-widget-type="rewards-earnings-v2"] .stamped-rewards-widget-campaign-image > i, 
    [data-widget-type="rewards-earnings-v2"] .stamped-rewards-widget-campaign-image > i:before {
      color: ${colors.charcoal} !important;
    }

    [data-widget-type="rewards-how-it-works"] div.stamped-rewards-widget-number {
      background-color: ${colors.charcoal};
    }
    
`

const RewardsPage = ({ location, data }) => {
    const path = location.pathname
    const { page } = data

    const { metaTitle, metaDescription, socialMediaImage, blocks } = page
    let sharingImage = false
    if (socialMediaImage && socialMediaImage.file) {
        sharingImage = socialMediaImage.file.url
    }



  return (
<Layout transparentHeader={false}>
    <GlobalStyle />
    <Seo
     path={path}
     title={metaTitle}
     description={metaDescription}
     fullTitle={false}
     image={sharingImage}></Seo>
    <PageContents blocks={blocks} />
</Layout>
  )
}

export default RewardsPage

export const pageQuery = graphql`
  query RewardspageQuery {
    page: contentfulPage(slug: { eq: "rewards" }) {
      id
      metaTitle
      metaDescription
      socialMediaImage {
        file {
          url
        }
      }
    }
  }
`

