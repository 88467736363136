import React from "react"
import { useEffect } from "react"
import { ButtonStyleDark } from "../../Core/Buttons/Styles"
import styled from "styled-components"

const DarkButton = styled.a`
  ${ButtonStyleDark}
  margin-bottom: 40px;
`

const componentHtml = (
  <div id="stamped-rewards-widget" 
  data-widget-type="rewards-spendings-v2" 
  data-label-title="How to Use Your Points" 
  data-label-description="Redeeming your points for discounts is easy-peasy. For one-time purchases, simply use the dropdown at checkout to use your points. For subscription-purchases, click below to generate a promo code you can use when checking out.">    
  </div>

)

const HowToUse = (props) => {
  useEffect(() => {
    if (window.StampedFn) {
      window.StampedFn.initRewards({})
      
    }    
  }, []);
  const triggerLauncher = () => {
    window.StampedFn.toggleRewardsModal();
  }
  return (
    <div>
      <div style={{ marginTop: "0px" }}>{componentHtml}</div>
      <DarkButton onClick={triggerLauncher}>REDEEM MY POINTS</DarkButton>
    </div>
  )
}

export default HowToUse


