import React from "react"
import { useEffect } from "react"
const componentHtml = (
  <div id="stamped-rewards-widget" 
    data-widget-type="rewards-how-it-works" 
    data-label-title="How it Works" 
    data-label-step-title-one="Join" 
    data-label-step-description-one="Make your first order to automatically start earning" 
    data-label-step-title-two="Earn" 
    data-label-step-description-two="Earn points every time you shop" 
    data-label-step-title-three="Redeem" 
    data-label-step-description-three=" Cash in for exclusive discounts">
  </div>
)

const HowItWorks = (props) => {
  useEffect(() => {
    if (window.StampedFn) {
      window.StampedFn.initRewards({})
    }
  }, [])
  return (
    <div>
      <div style={{ marginTop: "0px" }}>{componentHtml}</div>
    </div>
  )
}

export default HowItWorks


